<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <ACRUDTable
          :fields="[
            //'id',
            helpers.monthName,
            getHelperObject('early_payment_mode'),
            getHelperObject('early_payment_type'),
            helpers.rounding,
            { key: 'month_day', _classes: 'text-center' },
            helpers.decimalValue,
            { ...helpers.decimalValue, key: 'gap' },
            { ...helpers.validIcons, key: 'status_id' },
            { ...helpers.validIcons, key: 'up_to_date_only' },
            helpers.createdAt
          ]"
          :config="{
            url: 'admin/property_early_payment_discounts',
            route: '/admin/parameters/property_early_payment_discounts',
            name: 'property_early_payment_discount',
            params: { _lists: 'months,roundings,early_payment_modes,early_payment_types' },
            _active: false,
            duplicate: true,
            latest: 'month_id'
          }"
          @preparedHelpers="onPreparedHelpers"
        />
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'PropertyEarlyPaymentDiscounts',
  mixins: [crudMixin]
}
</script>
