var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('transition',{attrs:{"name":"slide"}},[_c('ACRUDTable',{attrs:{"fields":[
          //'id',
          _vm.helpers.monthName,
          _vm.getHelperObject('early_payment_mode'),
          _vm.getHelperObject('early_payment_type'),
          _vm.helpers.rounding,
          { key: 'month_day', _classes: 'text-center' },
          _vm.helpers.decimalValue,
          Object.assign({}, _vm.helpers.decimalValue, {key: 'gap'}),
          Object.assign({}, _vm.helpers.validIcons, {key: 'status_id'}),
          Object.assign({}, _vm.helpers.validIcons, {key: 'up_to_date_only'}),
          _vm.helpers.createdAt
        ],"config":{
          url: 'admin/property_early_payment_discounts',
          route: '/admin/parameters/property_early_payment_discounts',
          name: 'property_early_payment_discount',
          params: { _lists: 'months,roundings,early_payment_modes,early_payment_types' },
          _active: false,
          duplicate: true,
          latest: 'month_id'
        }},on:{"preparedHelpers":_vm.onPreparedHelpers}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }